// imports
@import "../../../styles/variables";


.arrow {
  margin-left: 10px; 
  font-size: 16px;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 220px;
}

.dropdownToggle {
  padding: 10px 20px; 
  background-color: #F4F7FC;
  color: black;
  border: 1px solid rgb(101, 99, 99);
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 220px;
}

.dropdownToggle:hover {
  background-color: #e1e1e1; 
}

.dropdownMenu {
  position: absolute;
  background-color: #F4F7FC;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
  z-index: 1000;
  width: 220px;

  white-space: normal;
  word-wrap: break-word;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdownItem {
  padding: 10px 15px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 220px;
  box-sizing: border-box; 
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}


.header {
  // position: fixed;
  // top: 0;
  // right: 0;
  // left: 0;
  // z-index: 20;
  // transition: 0.25s;
  border-bottom: 1px solid $border;
  width: 100%;
  z-index: 1000 !important;
  position: relative;
  box-shadow: 0px 0px 24px 0px #0000000d;
}

.scroll {
  background-color: $white;
  box-shadow: 0px 5px 15px -2px rgba(0, 0, 0, 0.07);
  .nav {
    border: none;
  }
}

.menuBar {
  font-size: 1.75rem;
  display: inline-flex;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  height: 80px;
  @media (max-width: map-get($breakpoints, lg)) {
    padding-top: 1rem;
  }
  @media (max-width: map-get($breakpoints, md)) {
    padding-top: 0.85rem;
    padding-bottom: 1.05rem;
  }
  @media (max-width: map-get($breakpoints, sm)) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    height: unset;
  }
}

.logo {
  display: flex;
  width: 100px;
  // height: 34px;

  img {
    pointer-events: none;
  }
}

.overlay {
  pointer-events: none;
  user-select: none;
  @media (max-width: map-get($breakpoints, lg)) {
    position: fixed;
    inset: 0;
    background-color: rgba($black, 50%);
    opacity: 0;
    transition: 0.35s ease;
  }
}

@media (max-width: map-get($breakpoints, lg)) {
  .menusWrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 28ch;
    max-width: 100%;
    margin-left: auto;
    height: 100%;
    background-color: $white;
    flex-direction: column;
    transform: translateX(100%);
    overflow-y: auto;
    transition: 0.35s ease;
    z-index: 20;
  }
}
.menusWrapper {
  height: 100%;
}
.menus {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
  height: 100%;
  @media (max-width: map-get($breakpoints, lg)) {
    flex-direction: column;
  }
  > li {
    height: 100%;
    display: flex;
    align-items: center;

    @media (min-width: map-get($breakpoints, lg)) {
      &:last-child {
        border-bottom: none;
      }
    }
    @media (max-width: map-get($breakpoints, lg)) {
      width: 100%;
      border-bottom: 1px solid $border;
      &:last-child {
        padding: 1rem 1.5rem;
      }
    }
    @media (max-width: map-get($breakpoints, sm)) {
      height: unset;
    }
    &:not(:last-child) {
      a {
        padding: 2rem 1.25rem 2rem 1.25rem;
        height: 100%;
        display: flex;
        align-items: center;
        @media (max-width: map-get($breakpoints, lg)) {
          padding: 1rem 1.5rem;
          display: flex;
        }
        &.active,
        &:hover {
          color: $primary;
          background-color: $secondary-4;
          font-weight: 700;
          border-bottom: 3px solid $primary;
          @media (max-width: map-get($breakpoints, lg)) {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none !important;
          }
        }
        &:not(:active):not(:hover) {
          // border-bottom: 3px solid $primary;
        }
      }
    }
  }
}

.menuTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $border;
  .logo {
    width: calc(7rem + 2vw);
    a {
      padding: 0;
    }
  }
  .menuClose {
    display: flex;
    font-size: 1.15rem;
    background-color: transparent;
    color: inherit;
    padding: 0;
    border-radius: 0.25rem;
    border: none;
    outline: none;
    z-index: 5;
    cursor: pointer;
  }
}

.header__content {
  &__selectors {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 2rem;
  }

  &__selector {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--color-grey);
    background-color: white;
    color: var(--color-text);
    font-size: 0.9rem;
    min-width: 200px;
    cursor: pointer;

    &:disabled {
      background-color: var(--color-grey-light);
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
      border-color: var(--color-primary);
    }
  }
}

.formBtn {
  display: flex;
  > * {
    flex-grow: 1;
  }
}

.button {
  flex-grow: 1;
  @media (min-width: map-get($breakpoints, lg)) {
    padding-top: 0.938rem;
    padding-bottom: 0.938rem;
  }
}

.buttonsDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
  @media (max-width: map-get($breakpoints, lg)) {
    display: flex;
    flex-direction: column;
  }
}
.active {
  color: $primary;
  background-color: $secondary-4;
  font-weight: 700;
  border-bottom: 3px solid $primary;
}
.menusBtnLink {
  height: 100%;

  a {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  @media (max-width: map-get($breakpoints, lg)) {
    padding-top: 20px;
    height: fit-content;

    button{
      width: 200px !important;
    }
  }
}

.dashboardProfileLink {
  display: flex;
  align-items: center;
}
