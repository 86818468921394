.container {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;

  span{
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  img{
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(18, 18, 18, 0) 0%,
    rgba(18, 18, 18, 0.75) 100%
  );
}
