// imports
@import "../../../styles/variables";

/**
--- variants ---
    style-2,
    style-3,
    style-4,
    style-5,
    style-6,
    style-7,
    style-8,
    style-10
    style-11
**/

.btnHelper {
  display: flex;
  @media (max-width: map-get($breakpoints, lg)) {
    justify-content: center;
  }
  &.center {
    justify-content: center;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  font-size: clamp(0.875rem, 1vw + 0.5rem, 1rem);
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  transition: 0.35s ease;
  justify-content: center;
  border: none;
  width: 100%;
  max-width: 300px;
  max-height: 50px;

  @media (max-width: map-get($breakpoints, lg)) {
    padding: 1rem 1.5rem;
  }
  @media (max-width: map-get($breakpoints, sm)) {
    padding: 0.75rem 1.25rem;
  }
  &[type="submit"] {
    width: 100%;
    padding-top: 0.813rem;
    padding-bottom: 0.813rem;
    // border-radius: 0;
    border-radius: 58px;
  }

  &[type="submit"] {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
}
.btnSizeHuge {
  width: 285px;
  height: 75px;
}

.btnSizeLg {
  padding: 22px 100px;
  font-family: $btn-font;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: map-get($breakpoints, lg)) {
    padding: 18px 60px;
  }
}

.btnSizeMd {
  font-size: 18.56px;
  font-family: $btn-font;
  font-style: normal;
  font-weight: 400;
  line-height: 27.84px; /* 150% */
  padding: 16px 54px;

  @media (max-width: map-get($breakpoints, lg)) {
    padding: 12px 54px;
  }
}

.btnSizeSm {
  padding: 8px 24px;
  font-size: 15px;
  font-family: $btn-font;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.btnSizeXs {
  padding: 6px 24px;
  font-size: 10.868px;
  font-family: $btn-font;
  font-style: normal;
  font-weight: 400;
  line-height: 17.389px;
}

.primary {
  background-color: $primary !important;
  color: $white !important;
  border-radius: 58px !important;

  &:hover {
    background-color: $primary-2;
    border-color: $tertiary-border;
  }

  .btnGradient {
    background: $gradient;
  }
}

.secondary {
  background-color: $secondary-btn-bg;
  border-color: $secondary-btn-border;
  border-radius: 58px;
  border: 1px solid $secondary-btn-border;
  width: 100%;
  &:hover {
    background-color: $secondary-btn-hover;
  }
}

.secondary-light {
  background-color: #e4ffe4;
  border-color: $secondary-btn-border;
  border-radius: 58px;
  border: 1px solid $secondary-btn-border;
  width: 100%;
}

.cta {
  background-color: $cta-btn;
  color: $white;
  border-radius: 58px;
  border-color: #d96704;

  &:hover {
    background-color: $cta-btn-hover;
    border-color: #f79403;
  }
}
.tertiary {
  background-color: #fff;
  border: 1px solid $tertiary-border;
  border-radius: 58px;
  &:hover {
    background-color: $secondary-btn-hover;
  }
  .btnGradient {
    background: $gradient-2;
  }
}
.menuBtn {
  background-color: $secondary;
  color: gray;
  text-align: start !important;
  display: unset;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 12px;
  height: 40px;
}
.danger {
  background-color: $red;
  color: $white;
  border-radius: 58px;

  &:hover {
    background-color: $red-hover;
    border-color: $black;
  }

  .btnGradient {
    background: $gradient;
  }
}
.loading {
  opacity: 0.5;
}
.icon {
  font-size: 1.25rem;
  @media (max-width: map-get($breakpoints, lg)) {
    font-size: 1.15rem;
  }
  @media (max-width: map-get($breakpoints, md)) {
    font-size: 1rem;
  }
  path {
    color: inherit;
    stroke: inherit;
  }
}
.mt--50 {
  margin-top: -50px !important;
}
.mw-150 {
  max-width: 150px !important;
}
.pill {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  // width: 116px;
  height: 28px;
  background-color: #f8f8f8;
  color: black;
  font-family: inherit;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  padding: 0 10px;
  overflow: hidden;
  transition: color 0.15s cubic-bezier(0.32, 0, 0.59, 0.03), background-color,
    border;
  border: 1px solid #e1e1e4;
  border-radius: 9999999px;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: $primary;
    background-color: #f8f8f8;
    border-color: #f3f3f4;
  }
  cursor: pointer;
  text-align: center;
  font-style: normal;
}

.transparent {
  background-color: transparent;
  color: gray;
  padding: 10px;
}

.pill-primary {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  height: 28px;
  margin-right: 0.5em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  background-color: $primary;
  color: white;
}

.pill-secondary {
  margin-right: 0.5em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid #3c8c30;
  background-color: #f7fff7;
  color: black;
}
