// imports
@import './reset';
@import './variables';


// base,
*{
    font-family: $btn-font;
}
// *,
// ::before,
// ::after {
//     font-family: $font;
// }

html {
    scroll-behavior: smooth;
}

body {
    color: $neutral !important;
    background-color: $body-bg !important;
    font-size: clamp(0.938rem, 1vw + 0.5rem, 1rem);
}

.row {
    display: flex;
    align-items: center;
    flex-direction: column;
    > * {
        width: 100%;
    }
    @media (min-width: map-get($breakpoints, lg)) {
        flex-direction: row;
        > * {
            width: 50%;
            flex-basis: 50%;
        }
    }
}

.w-100{
    width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
input:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset;
}
textarea {
    resize: vertical;
}

// next error
.next-error-h1 {
    border-color: $neutral !important;
}


.ReactModalPortal{
    z-index: 1000 !important;
    .ReactModal__Overlay{
        z-index: 1000 !important;
    }
}

.spin{
    // animation: spin 1s infinite linear;
    animation-name: spinner;
    animation-duration: 1s;
    /* Things added */
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    display: inline-block;
    /* <--- */
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.btn--primary{
    width : 100% !important;
    max-width: 200px !important;
}

.uploader__image-editor__header--empty-non-modal{
    min-height: unset !important;
}

.uploader__image-editor {
//   display: -webkit-box; 
    display: -webkit-flex !important;
    // display: -moz-box;
    display: -ms-flexbox !important;
    display: flex !important; 
        -webkit-box-pack: unset !important;
        justify-content: unset !important;
    -webkit-box-align: unset !important;
    align-items: unset !important;
    -webkit-box-orient: unset !important;
    -webkit-box-direction: unset !important;
    flex-direction: row !important;
    height: 100%;
    width: 100%;
}

.spinnerWrapperDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: $secondary;
}