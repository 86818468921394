// imports
@import '../../../styles/variables';

.footer {
    background-color: $footer-bg;
    color: $black !important;
}

.footerTop {
    padding: 5.313rem 0 4.063rem;
    display: grid;
    column-gap: 1.5rem;
    row-gap: 2rem;
    @media (min-width: map-get($breakpoints, sm)) {
        grid-template-columns: 1.5fr 1fr;
    }
    @media (min-width: map-get($breakpoints, md)) {
        grid-template-columns: 1.8fr 0.7fr 1fr;
    }
    @media (min-width: map-get($breakpoints, lg)) {
        grid-template-columns: 1.8fr 0.7fr 1fr 0.7fr 1fr;
    }
}

.logo {
    width: clamp(6.5rem, 10vw + 1rem, 9.375rem);
}

.text {
    font-size: 0.875rem;
    margin: 1.25rem 0;
    color: $white;
    @media (max-width: map-get($breakpoints, lg)) {
        text-align: left;
    }
}

.title {
    color: $primary;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
}

.menuLists {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    li {
        a {
            color: $white;
            font-size: 0.875rem;
            &:hover {
                text-decoration: underline;
                text-underline-offset: 0.25rem;
            }
        }
    }
}

.footerBottom {
    position: relative;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    gap: 0.65rem;
    padding: 2.5rem 0;
    border-top: 1px solid $secondary;
    @media (max-width: map-get($breakpoints, md)) {
        flex-direction: column;
        text-align: center;
        padding: 2rem 0;
    }
}

.copyText {
    display: flex;
    gap: 0.2rem;

    @media (max-width: map-get($breakpoints, md)) {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    span {
        color: $primary;
        font-weight: 700;
    }
}

.footLink {
    &:hover {
        text-decoration: underline;
        text-underline-offset: 0.25em;
    }
}
.licensetext{
    color: $primary;
}