// imports
@import "../../../styles/variables";

$header-pl: 0.875rem;
.header {
  display: flex;
  align-items: center;
  background-color: rgba($clr-2, 30%);
  padding: 0.938rem 2.375rem 0.938rem $header-pl;
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: 0.35s;
  z-index: 10;
  max-height: $chat-header-height;
  @media (max-width: map-get($breakpoints, lg)) {
    justify-content: space-between;
  }
}

.logoWrapper {
  display: flex;
  @media (min-width: map-get($breakpoints, lg)) {
    width: calc(($chat-left-side + $chat-left-channel-bar) - $header-pl);
    flex-basis: calc(($chat-left-side + $chat-left-channel-bar) - $header-pl);
  }
  .logo {
    display: flex;
    width: calc(10.5ch + 4vw);
  }
}

.pagSear {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  border-left: 1px solid $border-4;
  @media (max-width: map-get($breakpoints, lg)) {
    display: none;
  }
}

.channelPagination {
  display: flex;
  align-items: center;
  .channelIcon {
    margin-right: 1rem;
  }
  .paginations {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    li {
      a {
        position: relative;
        color: $black;
        font-weight: 700;
        text-underline-offset: 0.25em;
        &:hover {
          text-decoration: underline;
        }
      }
      .divider {
        font-weight: 700;
        padding-left: 0.35rem;
      }
    }
  }
}

.search {
  background-color: $soft-stoke;
  color: $neutral-3;
  display: flex;
  align-items: center;
  gap: 0.938rem;
  padding: 0.938rem 1.25rem 0.938rem 1rem;
  border-radius: 6.25rem;
  width: 25rem;
  .submit,
  .input {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .submit {
    display: inline-flex;
    color: inherit;
    padding: 0;
    cursor: pointer;
    font-size: 1.125rem;
  }
  .input {
    flex-grow: 1;
  }
  .adjust {
    position: relative;
    display: inline-flex;
  }
}

.profileInfo {
  display: flex;
}

.notify {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  position: relative;

  @media (max-width: map-get($breakpoints, lg)) {
    gap: 1rem;
  }

  .notifyIcon {
    position: relative;
    display: inline-flex;
    font-size: 1.5rem;
    background-color: transparent;
    color: $neutral-3;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .activity {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: $clr-5;
    color: $white;
    font-size: 0.75rem;
    width: 1rem;
    height: 1rem;
    display: inline-grid;
    place-items: center;
    border-radius: 50%;

    @media (max-width: map-get($breakpoints, lg)) {
      font-size: 0.625rem;
      width: 0.85rem;
      height: 0.85rem;
    }
  }
}


// .activity {
//   $size: 1rem;
//   width: $size;
//   height: $size;
//   display: inline-grid;
//   place-items: center;
//   border-radius: 50%;
//   position: absolute;
//   right: 0;
//   z-index: 1;
//   @media (max-width: map-get($breakpoints, lg)) {
//     $size: 0.85rem;
//     width: $size;
//     height: $size;
//   }
//   &::before {
//     content: "";
//     border: 0.188rem solid $clr-4;
//     position: absolute;
//     inset: -0.188rem;
//     z-index: -1;
//     border-radius: 50%;
//   }
//   &.pc {
//     top: 0;
//     background-color: $clr-3;
//     color: $white;
//     font-size: 0.75rem;
//     @media (max-width: map-get($breakpoints, lg)) {
//       font-size: 0.55rem;
//     }
//   }
//   &.count {
//     bottom: 0;
//     background-color: $clr-5;
//     color: $white;
//     font-size: 0.625rem;
//     font: 2700px;
//     @media (max-width: map-get($breakpoints, lg)) {
//       font-size: 0.5rem;
//     }
//   }
// }
// .activityCount {
//   bottom: 10px;
//   left: 10px;
//   background-color: $clr-5;
//   color: $white;
//   font-size: 0.625rem;
//   font: 2700px;
//   @media (max-width: map-get($breakpoints, lg)) {
//     font-size: 0.5rem;
//   }
// }

.menuBar {
  font-size: 1.75rem;
  display: inline-flex;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid $border;
  line-height: 1;
  z-index: 100;

  @media (max-width: map-get($breakpoints, lg)) {
    padding-top: 1rem;
  }

  @media (max-width: map-get($breakpoints, md)) {
    padding-top: 0.85rem;
    padding-bottom: 1.05rem;
  }

  @media (max-width: map-get($breakpoints, sm)) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.logo {
  display: flex;

  @media (min-width: map-get($breakpoints, lg)) {
    padding-top: 1.188rem;
  }

  img {
    pointer-events: none;
  }

  @media (max-width: map-get($breakpoints, lg)) {
    width: calc(8rem + 4vw);
  }
}

.overlay {
  pointer-events: none;
  user-select: none;
  height: 100vh;

  @media (max-width: map-get($breakpoints, lg)) {
    position: fixed;
    inset: 0;
    background-color: rgba($black, 50%);
    opacity: 0;
    transition: 0.35s ease;
  }
}

@media (max-width: map-get($breakpoints, lg)) {
  .menusWrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 28ch;
    max-width: 100%;
    margin-left: auto;
    height: 100%;
    background-color: $white;
    flex-direction: column;
    transform: translateX(100%);
    overflow-y: auto;
    transition: 0.35s ease;
    z-index: 1000;
    height: 100vh;
  }
}

.menus {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;

  @media (max-width: map-get($breakpoints, lg)) {
    flex-direction: column;
  }

  > li {
    @media (min-width: map-get($breakpoints, lg)) {
      &:last-child {
        padding-top: 1.188rem;
      }
    }

    @media (max-width: map-get($breakpoints, lg)) {
      width: 100%;
      border-bottom: 1px solid $border;

      &:last-child {
        padding: 1rem 1.5rem;
      }
    }

    &:not(:last-child) {
      a {
        padding: 2rem 1.25rem 1.25rem;

        @media (max-width: map-get($breakpoints, lg)) {
          padding: 1rem 1.5rem;
          display: flex;
        }

        &.active,
        &:hover {
          color: $primary;
          background-color: $secondary-4;
          font-weight: 700;
        }
      }
    }
  }
}

.menuTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid $border;

  .logo {
    width: calc(7rem + 2vw);

    a {
      padding: 0;
    }
  }

  .menuClose {
    display: flex;
    font-size: 1.15rem;
    background-color: transparent;
    color: inherit;
    padding: 0;
    border-radius: 0.25rem;
    border: none;
    outline: none;
    z-index: 5;
    cursor: pointer;
  }
}

.formBtn {
  display: flex;

  > * {
    flex-grow: 1;
  }
}

.button {
  flex-grow: 1;

  @media (min-width: map-get($breakpoints, lg)) {
    padding-top: 0.938rem;
    padding-bottom: 0.938rem;
  }
}
.wrapper {
  position: relative;
  display: flex;
  .userProfile {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    .userImg {
      display: inline-flex;
      img {
        border: 2px solid $primary !important;
        border-radius: 50%;
      }
    }
    .userName {
      font-size: 1rem;
      font-weight: 500;
    }
    .icon {
      font-size: 0.75rem;
    }
  }
  .userMenu {
    padding-top: 1rem;
    opacity: 0;
    visibility: hidden;
    transform: translateY(1rem);
    transition: 0.25s;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
  &:hover {
    .userMenu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.menuLists {
  background-color: $white;
  border-radius: 0.25rem;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.07);
  li {
    a {
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      font-size: 0.875rem;
      padding: 0.75rem 1rem;
      &:hover {
        background-color: $neutral-5;
      }
    }
  }
  @media (max-width: map-get($breakpoints, lg)) {
    flex-direction: column;
  }
  > li {
    @media (max-width: map-get($breakpoints, lg)) {
      width: 100%;
      border-bottom: 1px solid $border;
    }

    a {
      padding: 2rem 1.25rem 1.25rem;
      @media (max-width: map-get($breakpoints, lg)) {
        padding: 1rem 1.5rem;
        display: flex;
      }
      &.active,
      &:hover {
        color: $primary;
        background-color: $secondary-4;
        font-weight: 700;
      }
    }
  }
}
.profileWrapper{
  display: flex;
  gap: 1.25rem;
  max-height: $chat-header-height;
}