// imports
@import '../../../styles/variables';

.container {
    max-width: $container;
    margin: 0 auto;
    padding: 0 $containerPadding;

    @media screen and (max-width: 768px) {
        padding: 2vw;
      }
}
