// imports
@import "../../../styles/variables";

.wrapper {
  position: relative;
  display: flex;
  z-index: 1000 !important;
  .userProfile {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    .userImg {
      display: inline-flex;
      border: 2px solid #dddddd !important;
      border-radius: 50%;
      img {
        border: 2px solid $primary !important;
        border-radius: 50%;
      }
    }
    .userName {
      font-size: 1rem;
      font-weight: 500;
    }
    .icon {
      font-size: 0.75rem;
    }
  }
  .userMenu {
    padding-top: 1rem;
    opacity: 0;
    visibility: hidden;
    // transform: translateY(1rem);
    transition: 0.25s;
    position: absolute;
    top: 100%;
   
    left: 0;
    right: 0;
     z-index: 1000 !important;
    .menuLists {
      background-color: $white;
      border-radius: 0.25rem;
      box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.07);
       z-index: 1000 !important;
      li {
         z-index: 1000 !important;
        a {
          position: relative;
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          font-size: 0.875rem;
          padding: 0.75rem 1rem;
           z-index: 1000 !important;
          &:hover {
            background-color: $neutral-5;
          }
        }
      }
    }
  }
  &:hover {
    .userMenu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
       z-index: 1000 !important;
    }
  }
}
