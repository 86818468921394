// imports
@import "../../../styles/variables";

/**
--- variants ---
    heading --> variantType: --2
    heading-2
    title-2
    title-3
    title-4
    title-5
    body-3
**/

.text {
  line-height: 1.6;
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}

.heading,
.heading-2 {
  font-family: $font-2;
  font-weight: 300 !important;
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}

.heading {
  position: relative;
  color: $primary;
  font-size: clamp(1.25rem, 3.25vw + 1rem, 2.813rem);
  padding-bottom: 0.938rem;
  line-height: 1.1;
  &.--2 {
    span {
      color: $secondary;
    }
  }
  &::after {
    content: "";
    width: 6.25rem;
    height: 3px;
    background-color: $secondary;
    position: absolute;
    bottom: 0;
    left: 0;
    font-family: $font;
    @media (max-width: map-get($breakpoints, lg)) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.--2 {
    &::after {
      background-color: $primary-2;
      font-family: $font;
    }
  }
}

.heading-2 {
  font-size: clamp(1.15rem, 3.25vw + 1rem, 2.5rem);
  line-height: 1.3;
}

.title-2,
.title-4,
.title-5 {
  font-size: clamp(1rem, 2vw + 0.5rem, 1.25rem);
  font-weight: 400;
  font-family: $font;
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}

.title-2 {
  color: $black;
  border-bottom: 2px solid $secondary;
}

.title-3 {
  color: $primary;
  font-family: $font-2;
  font-size: clamp(1.05rem, 2vw + 0.5rem, 1.5rem);
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}

.title-4,
.title-5 {
  color: $primary;
}

.title-4 {
  font-weight: 400;
  margin-bottom: 1.25rem;
  span {
    font-weight: 700;
  }
}

.body-1 {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}

.body-2 {
  font-size: 0.938rem;
  line-height: 1.5;
  font-weight: 400;
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}

.body-3 {
  font-size: 0.875rem;
  line-height: 1.5;
  span {
    font-weight: 300;
  }
}
.body-4 {
  font-size: 0.938rem;
  line-height: 1.5;
  font-weight: 600;
  color: $primary;
  text-decoration: underline $secondary;
  @media (max-width: map-get($breakpoints, lg)) {
    text-align: center;
  }
}
.body-5 {
  font-size: 0.75rem;
  line-height: 1;
  span {
    font-weight: 300;
  }
}

.body-6 {
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 300;
}
.danger{
  color: $red;
  font-weight: 500;
  font-size: 0.75rem;
}